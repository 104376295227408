import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/artist/artistView.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { IoRemoveOutline } from "react-icons/io5";
import { FaGripLinesVertical } from "react-icons/fa6";
import { FaUserCheck, FaUserXmark } from "react-icons/fa6";
import { MdOutlineRunningWithErrors } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";

const ArtistView = () => {

  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = useState("Deactive");
  const [approveStatus, setApproveStatus] = useState("Approve Account");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();


  const [dropDownTrackManage, setDropDownTrackManage] = useState({
    id: 0,
    open: false
  });

  const [artistData, setArtistData] = useState([]);
  const [artistTrackData, setArtistTrackData] = useState([]);
  const [artistAlbumData, setArtistAlbumData] = useState([]);
  const [artistPlaylistData, setArtistPlaylistData] = useState([]);
  const [artistcountryName, setArtistCountryName] = useState();

  const [dropDownArtistManage, setDropDownArtistManage] = useState({
    id: 0,
    open: false
  });
  const [deleteArtistDrop, setDeleteArtistDrop] = useState(false);

  const changeArtistStatus = async (changeStatus) => {
    try {
      const token = localStorage.getItem("token");
      const changeApproveStatus = await axios({
        method: 'POST',
        url: `${baseUrl}admin/artist-update-status`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          "id": id,
          "status": changeStatus
        }
      });

      const result = changeApproveStatus;
      console.log("Result : ", result);
      if (result.status === 200) {
        setActiveStatus(changeStatus === 1 ? "Active" : "Deactive");
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: "Artist status change successfully." }));
      }
    } catch (error) {
      console.log(error);
    }

    setActiveStatus(activeStatus === "Active" ? "Deactive" : "Active");
  }

  const changeApproveStatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const changeApproveStatus = await axios({
        method: 'POST',
        url: `${baseUrl}admin/artist-update-approved-status`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          "id": id,
          "approved_status": 1
        }
      });

      const result = changeApproveStatus;
      console.log("Result : ", result);
      if (result.status === 200) {
        setApproveStatus("Approve");
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: "Artist Approved Successfully." }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteArtist = async () => {

    try {
      const token = localStorage.getItem('token');
      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/artist/delete/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      console.log("Test data: ", result);
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        navigate("/artist");
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  }

  function handleClick() {
    navigate("/artist");
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    const getArtistData = async () => {

      try {
        const getArtistList = await axios.get(`${baseUrl}admin/artist/get/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getArtistList;
        console.log(result);
        if (result.data.status === 200) {
          setArtistData(result.data.data);
          setArtistTrackData(result.data.data.top_tracks);
          setArtistAlbumData(result.data.data.top_albums);
          setArtistPlaylistData(result.data.data.top_playlists);
          setArtistCountryName(result.data.data.citizenship.name);

          if (result.data.data.approved_status === null || result.data.data.approved_status === 0) {
            setApproveStatus("Approve Account");
          } else {
            setApproveStatus("De-Approve");
          }

          if (result.data.data.status === null || result.data.data.status === 0) {
            setActiveStatus("Deactive");
          } else {
            setActiveStatus("Active");
          }
          // (result.data.data.approved_status)
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getArtistData();
  }, []);

  return (
    <div className={`${Styles.artist}`}>

      {
        deleteArtistDrop && (
          <div className={`${Styles.deleteArtist}`}>
            <div className={`${Styles.innerDeleteArtist}`}>
              <div className={`${Styles.heading}`}>
                <p>Do you really want to delete this artist?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                <button onClick={handleDeleteArtist}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Artist"}</button>
                <button onClick={() => { setDeleteArtistDrop(false); setDropDownArtistManage({ id: 0, open: false }); }}>Skip & Continue</button>
              </div>
            </div>
          </div>
        )
      }

      <div className={`${Styles.artist_heading}`}>
        <h2>Artist</h2>
      </div>

      <div className={`${Styles.artist_nav}`}>

        {/* Artist Details */}
        <div className={`${Styles.artist}`}>
          <div className={`${Styles.artist_info}`}>
            <div className={`${Styles.artist_navigate}`}>
              <button onClick={handleClick}>
                <RiArrowLeftSLine /> Back To Artist
              </button>
            </div>

            {/* Name || Active || Deactive */}
            <div className={`${Styles.artist_details}`}>
              {/* <p>Artist</p> */}
              <div className={`${Styles.artist_name_status}`}>
                <h2>{artistData.artist_name}</h2>
                <p>{activeStatus}</p>
                <div className={`${Styles.part}`}>
                  <FaGripLinesVertical />
                </div>
                <p>{approveStatus === "Approve Account" ? "Approval Pending" : "Approved"}</p>
              </div>
            </div>

            {/* Artist Row Data */}
            <div className={`${Styles.artist_counts}`}>
              <div className={`${Styles.artist_albums}`}>
                <p>Album</p>
                <IoRemoveOutline />
                <p>{artistData.total_albums}</p>
              </div>
              <div className={`${Styles.part}`}>
                <FaGripLinesVertical />
              </div>
              <div className={`${Styles.artist_songs}`}>
                <p>Songs</p>
                <IoRemoveOutline />
                <p>{artistData.total_music_tracks}</p>
              </div>
              <div className={`${Styles.part}`}>
                <FaGripLinesVertical />
              </div>
              <div className={`${Styles.artist_songs}`}>
                <p>Playlist</p>
                <IoRemoveOutline />
                <p>{artistData.total_playlists}</p>
              </div>
            </div>

            {/* Approve Deactive Button */}
            <div className={`${Styles.buttons}`}>
              <div className={`${Styles.active}`}>
                {activeStatus === "Deactive" &&
                  <>
                    <FaUserCheck className={`${Styles.activeSvg}`} />
                    <button className={`${Styles.active_btn}`} onClick={() => changeArtistStatus(1)}>Active Account</button>
                  </>
                }
                {activeStatus === "Active" &&
                  <>
                    <FaUserXmark className={`${Styles.deactiveSvg}`} />
                    <button className={`${Styles.active_btn}`} onClick={() => changeArtistStatus(0)}>Deactive Account</button>
                  </>
                }
              </div>
              {approveStatus === "Approve Account" &&
                <div className={`${Styles.approve}`}>
                  <MdOutlineRunningWithErrors className={`${Styles.pendingSvg}`} />
                  <button className={`${Styles.approve_btn}`} onClick={changeApproveStatus}>{approveStatus}</button>
                </div>
              }
              <div className={`${Styles.delete}`}>
                <MdDelete className={`${Styles.deleteSvg}`} />
                <button className={`${Styles.delete_btn}`} onClick={() => { setDeleteArtistDrop(!deleteArtistDrop); }}>Delete</button>
              </div>
            </div>

            {/* Artist Personal Info */}
            <div className={`${Styles.artist_personal_details}`}>
              <div className={`${Styles.personal_info}`}>
                <div className={`${Styles.artist_stageName}`}>
                  Stage Name:
                  <p>{artistData.stage_name}</p>
                </div>
                <div className={`${Styles.artist_dob}`}>
                  Date Of Birth:
                  <p>{new Date(artistData.dob).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</p>
                </div>
              </div>
              <div className={`${Styles.personal_info}`}>
                <div className={`${Styles.artist_phoneNumber}`}>
                  Phone Number:
                  <p>{artistData.phone_number}</p>
                </div>
                <div className={`${Styles.artist_nationality}`}>
                  Country:
                  <p>{artistcountryName}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${Styles.artist_image}`}>
            <img src={artistData.image_url} alt={artistData.artist_name} />
          </div>
        </div>

        {/* Top Track */}
        <div className={`${Styles.artist_track}`}>
          <div className={`${Styles.track_heading}`}>
            <h2>Top Tracks</h2>
          </div>
          <div className={`${Styles.track_info}`}>
            {
              artistTrackData.length === 0 &&
              <div className={`${Styles.dataNotFound}`}> Data Not Found </div>
            }
            {
              artistTrackData.map((data) => (
                <div className={`${Styles.track_content}`}>
                  <div className={`${Styles.track_image}`}>
                    <img src={data.cover_image} alt="Track Image" />
                  </div>
                  <p className={`${Styles.title}`}>{data.title}</p>
                  <p className={`${Styles.description}`}>{data.description}</p>
                </div>
              ))
            }
          </div>
        </div>

        {/* Top Album */}
        <div className={`${Styles.artist_album}`}>
          <div className={`${Styles.album_heading}`}>
            <h2>Top Albums</h2>
          </div>
          <div className={`${Styles.album_info}`}>
            {
              artistAlbumData.length === 0 &&
              <div className={`${Styles.dataNotFound}`}> Data Not Found </div>
            }
            {
              artistAlbumData.map((data) => (
                <div className={`${Styles.track_content}`}>
                  <div className={`${Styles.track_image}`}>
                    <img src={data.cover_image_url} alt="Track Image" />
                  </div>
                  <p className={`${Styles.title}`}>{data.title}</p>
                  <p className={`${Styles.description}`}>{data.description}</p>
                </div>
              ))
            }
          </div>
        </div>

        {/* Top Playlist */}
        <div className={`${Styles.artist_playlist}`}>
          <div className={`${Styles.playlist_heading}`}>
            <h2>Top Playlist</h2>
          </div>
          <div className={`${Styles.playlist_info}`}>
            {
              artistPlaylistData.length === 0 &&
              <div className={`${Styles.dataNotFound}`}> Data Not Found </div>
            }
            {
              artistPlaylistData.map((data) => (
                <div className={`${Styles.track_content}`}>
                  <div className={`${Styles.track_image}`}>
                    <img src={data.cover_image} alt="Track Image" />
                  </div>
                  <p className={`${Styles.title}`}>{data.title}</p>
                  <p className={`${Styles.description}`}>{data.description}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtistView
