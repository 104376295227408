import React, { useState } from "react";
import Styles from "../../assets/css/components/admin/language/languageAdd.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LanguageAdd = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const dispatch=  useDispatch();

    const [formData,setFormData] = useState({
      artist_id: userData.id,
      name: "",
      status: ""
    });

    const [error, setError] = useState({
      name: false,
      status: false,
    });

    const handleSubmit = async (e) => {

        e.preventDefault();

        const errorData = {
        name: false,
        status: false,
        }

        if(formData.name === "") errorData.name = true;
        if(formData.status === "") errorData.status = true;

        if(formData.name === "" || formData.status === ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
        }

        setError(errorData);
        const formDataSet = new FormData();
        formDataSet.append('name', formData.name);
        formDataSet.append('status', formData.status);
        formDataSet.append('artist_id', formData.artist_id);

        const token = localStorage.getItem("token");

        try{

        const data = await axios({
            method: 'POST',
            url: `${baseUrl}admin/language/create`,
            data: {
                "id": formData.id,
                "name": formData.name,
                "status": formData.status,
            },
            headers: {
            'Authorization': 'Bearer ' + token
            }
        });

        const result = data;
        console.log(result);
        if(result.data.status === 200){
            setLoading(false);
            dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
            navigate('/language');
        }else{
            console.log("Language Add Failed");
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
            setLoading(false);
        }
        }catch (error){

        console.log(error);
        if(error.response.data.status === 422){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
            setLoading(false);
            return;
        }

        // if(error !== ""){
        //     dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        //     setLoading(false);
        //     return;
        // }
        }
    };

  return (
    <div className={`${Styles.language}`}>
        <div className={`${Styles.language_heading}`}>
          <h2>Language</h2>
        </div>
  
        <div className={`${Styles.languageAdd}`}>   
            <div className={`${Styles.form_heading}`}>
                <h3>Add New User</h3>
                <button onClick={() => navigate("/language")}>
                    <RiArrowLeftSLine /> Go To Language List
                </button>
            </div>
  
            <div className={`${Styles.add_form}`}>
                <div className={`${Styles.form_grid}`}>
                    <div className={`${Styles.form_field}`}>
                    <label>Language Name</label>
                    <input type="text" placeholder="Enter Language Name" name="name" onChange={(e) => setFormData({...formData, name:e.target.value})} />
                    </div>
                    <div className={`${Styles.form_field}`}>
                    <label>Status</label>
                    <select name="status" onChange={(e) => setFormData({...formData, status:parseInt(e.target.value)})} >
                        <option value="">***** Select Status *****</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    </div>
                </div>
  
                <div className={`${Styles.buttons}`}>
                <button className={`${Styles.action}`} onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
                <button className={`${Styles.cancel}`} onClick={() => navigate("/language")}>Cancel</button>
                </div>
          </div>

        </div>
    </div>
  )
}

export default LanguageAdd
