import React from 'react'
import Styles from "../assets/css/channel.module.scss";
import { Outlet } from 'react-router-dom'

const Channel = () => {
    return (
        <div className={`${Styles.channel}`}>
    
          {/* heading & filter */}
          <div className={`${Styles.channel_heading}`}>
            <h2>Channel</h2>
          </div>
    
    
          {/* Playlist Headings */}
          <Outlet />
          
        </div>
    )
}

export default Channel
