import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/support/support.module.scss";
import ProfileLogo from "../../assets/images/Admin/profile.png";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import { IoMdClose } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
import { BsSortDown } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BiCheck } from "react-icons/bi";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import Pagination from "../common/Pagination";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";


const Support = () => {
  
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [sortingValue, setSortingValue] = useState("oldestDate");
  const [contentLoader, setContentLoader] = useState('true');
  
  const [statusFilter, setStatusFilter] = useState('');
  const [issuerFilter, setIssuerFilter] = useState('');
  const [filterDate, setFilterDate] = useState({
    from: '',
    to: ''
  });
  const [issueFilter, setIssueFilter] = useState({
    from: '',
    to: '',
    search: '',
    sorting: 'desc',
    page: "1",
    limit: "5"
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 6,
    showing: 5
  });

  const dateStatusApply = () => {
    setIssueFilter({...issueFilter, from: filterDate.from, to: filterDate.to, status: statusFilter, issuer:issuerFilter});
    setIsOpen(false);
  };

  const handleClear = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
    setIssuerFilter('');
  };

  const handleReset = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
    setIssuerFilter('');
    setIssueFilter({...issueFilter, from: "", to: "", status: ""});
    setIsOpen(false);
  };

  const [issueData, setIssueData] = useState([]);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(1);
  const [deleteId, setDeleteId]= useState(null);
  const [loading, setLoading] = useState(false);

  const [dropDownIssueManage, setDropDownIssueManage] = useState({
    id: 0,
    open: false
  });
  
  const [deleteIssueDrop, setDeleteIssueDrop] = useState(false);
  
  
  const handleDropDown = (e) => {
    let value = parseInt(e.currentTarget.dataset.issueId);
    if(dropDownIssueManage.id === value){
      setDropDownIssueManage({...dropDownIssueManage, open: !(dropDownIssueManage.open)})
    }

    if(dropDownIssueManage.id !== value){
      setDropDownIssueManage({id: value, open: true});
    }
  }

  const changePage = (count, type) => {

    if(type === "page"){
      setIssueFilter({...issueFilter, page: count});
    }

    if(type === "show"){
      setIssueFilter({...issueFilter, page: "1", limit: count});
      setPagination({...pagination, showing: count});
    }
  };



  useEffect(() => {

    const token = localStorage.getItem("token");
    const getIssueData = async () => {
      try {
        const getIssueList = await axios.get(`${baseUrl}admin/support/list`, {
          params: {
            from: issueFilter.from,
            to: issueFilter.to,
            status: statusFilter,
            issuer: issuerFilter,
            search: issueFilter.search,
            sort: issueFilter.sorting,
            page: issueFilter.page,
            limit: issueFilter.limit
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getIssueList;
        console.log(result);
        if(result.data.status === 200){
          setContentLoader(result.data.data.user.length === 0 ? true : false);
          setIssueData(result.data.data.user);
          setPagination({
            ...pagination,
            currentPage: result.data.data.pagination.currentPage,
            totalPage: result.data.data.pagination.totalPage,
          });
        }
      } catch (error) { 
        console.log("Error : ", error);
      }
    }
    getIssueData();
    }, [issueFilter, refresh]);

    const handleDeleteIssue = async () => {
      if(deleteId != null){

        try{
          const token = localStorage.getItem("token");
          const data = await axios({
            method: 'POST',
            url: `${baseUrl}admin/support/delete/${deleteId}`,
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });

        const result = data;
          if(result.data.status === 200){
            setLoading(false);
            setDeleteIssueDrop(false);
            dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
            setDropDownIssueManage({...dropDownIssueManage, open: false});
            setRefresh(refresh+1);
          }else{
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
            setLoading(false);
            setDeleteIssueDrop(false);
          }

        }catch (error){
  
          console.log(error);
          setDeleteIssueDrop(false);
          if(error.response.data.status === 422){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
            setLoading(false);
            return;
          }
  
          console.log(error.response.data.errors.password);
  
          if("password" in error.response.data.errors){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.password[0]}));
            setLoading(false);
            return;
          }
        }
      }else{
      }
    }

  return (
    <div className={`${Styles.support}`}>

      {
        deleteIssueDrop && (
        <div className={`${Styles.deleteIssue}`}>
          <div className={`${Styles.innerDeleteIssue}`}>
            <div className={`${Styles.heading}`}>
              <p>Do you really want to delete this issue?</p>
            </div>

            <div className={`${Styles.delete_image}`}>
              <img src={DeleteIllustration} alt="not supported" />
            </div>

            <div className={`${Styles.buttons}`}>
              <button onClick={handleDeleteIssue}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Issue"}</button>
              <button onClick={() => {setDeleteIssueDrop(false); setDropDownIssueManage({id: 0, open: false});}}>Skip & Continue</button>
            </div>
          </div>
        </div>
        )
      }

      <div className={`${Styles.support_heading}`}>
        <h2>Support</h2>
      </div>

      <div className={`${Styles.support_nav}`}>

        {/* Filter And Search Data */}
        <div className={`${Styles.filter_search_btn}`}>
          <div className={`${Styles.filter_sort}`}>
                <div className={`${Styles.filter}`}>
                  <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                  {isOpen && (
                  <div className={`${Styles.filter_dropdown}`}>
                    <div className={`${Styles.heading}`}>
                      <p>Filter</p>
                      <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                    </div>
  
                    <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div>
  
                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Status</p>
                        <p onClick={() => setStatusFilter('')}>Clear</p>
                      </div>
                      <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">**** Select Status ****</option>
                        <option value="0">Pending</option>
                        <option value="1">Completed</option>
                      </select>
                    </div>

                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Issuer  Name</p>
                        <p onClick={() => setIssuerFilter('')}>Clear</p>
                      </div>
                      <select name="issuerFilter" id="issuerFilter" value={issuerFilter} onChange={(e) => setIssuerFilter(e.target.value)}>
                        <option value="">**** Select Issuer Name ****</option>
                        <option value="user">User</option>
                        <option value="artist">Artist</option>
                      </select>
                    </div>
  
                    <div className={`${Styles.buttons}`}>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={dateStatusApply}>Apply Now</button>
                    </div>
                  </div>
                  )}
                </div>
  
                <div className={`${Styles.sort}`}>
                  <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                  {isSortOpen && (
                    <div className={`${Styles.sort_dropdown}`}>
                      {/* <p onClick={() => {setIssueFilter({...issueFilter, sorting: "user"}); setIsSortOpen(false);}}><span>User</span><span>{(issueFilter.sorting === "user") ? <BiCheck /> : ""}</span></p> */}
                      {/* <p onClick={() => {setIssueFilter({...issueFilter, sorting: "artist"}); setIsSortOpen(false);}}><span>Artist</span><span>{(issueFilter.sorting === "artist") ? <BiCheck /> : ""}</span></p> */}

                      <p onClick={() => {setIssueFilter({...issueFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(issueFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setIssueFilter({...issueFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(issueFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setIssueFilter({...issueFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(issueFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setIssueFilter({...issueFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(issueFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                    </div>
                  )}
                </div>
          </div>
          <div className={`${Styles.search}`}>
            <input type="text" placeholder="Search" onInput={(e) => setIssueFilter({...issueFilter, search: e.target.value})} />
          </div> 
        </div>

        {/* Issue Table */}
        <div className={`${Styles.user_List}`}>
          <div className={`${Styles.user_table}`}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Issuer Name</th>
                  <th>Artist/User</th>
                  <th>Issue</th>
                  <th>Created Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  issueData.length === 0 &&
                  <tr><td colSpan="7">
                    <div className={`${Styles.dataNotFound}`}>
                      {
                        contentLoader &&
                        <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                      }
                      {
                        !contentLoader &&
                        <p>Data not found</p>
                      }
                    </div>
                  </td></tr>
                }
                {
                  issueData.map((data, index) => {
                    return (
                      <tr key={data.id}>
                      <td>{data.id}</td>
                      <td className={`${Styles.image_name}`}>
                        <div className={`${Styles.image}`}>
                          <img src={ProfileLogo} alt="Track Picture1" />
                        </div>
                        <div className={`${Styles.user_name}`}>
                          <p>{data.issuer_name}</p>
                        </div>
                      </td>
                      <td>{data.user_type}</td>
                      <td>
                        <div className={`${Styles.issue_description}`}>
                          <p>{data.issue_description}</p>
                        </div>
                      </td>
                      <td>{new Date(data.createdAt).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/\s+/g, '-')}</td>
                      <td>{data.status === 0 ? "Pending" : "Completed"}</td>
                      <td>
                        <div className={`${Styles.manage}`}>
                          <PiDotsThreeOutlineFill data-issueId={data.id} onClick={handleDropDown} />
                          {
                            dropDownIssueManage.id ==
                            data.id && dropDownIssueManage.open === true && (
                              <div className={`${Styles.manageUser}`}>
                                <p onClick={() => navigate(`/support/edit/${data.id}`)} ><span><FiEdit /></span> <span>Edit</span></p>
                                <p onClick={() => {setDeleteIssueDrop(!deleteIssueDrop); setDeleteId(data.id)} }><span><RiDeleteBin6Line /></span> <span>Delete</span></p>
                              </div>
                            )
                          }
                        </div>
                      </td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div>
          <Pagination data={pagination}  pageClick={changePage}/> 
        </div>
      </div>
    </div>
  )
}

export default Support
