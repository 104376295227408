import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/adminUser/userEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import userprofile from "../../assets/images/Admin/admin_profile.png";

const UserEdit = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading]= useState(false);

  const [ userData, setUserData] = useState({
    id: null,
    first_name: "",
    email: "",
    dob: "",
    phone_number: "",
    gender: "",
    status: "",
    image_url: ""
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    const getUserData = async () => {

      try {

        const getUserList = await axios.get(`${baseUrl}admin/user/get/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getUserList;
        console.log(result);
        if(result.data.status === 200){
          const convertedDate = formatDate(result.data.data.user.dob);

          console.log(convertedDate);
          setUserData({
            id: result.data.data.user.id,
            first_name: result.data.data.user.first_name,
            email: result.data.data.user.email,
            dob: convertedDate,
            phone_number: result.data.data.user.phone_number,
            gender: result.data.data.user.gender, 
            status: result.data.data.user.status,
            image_url: result.data.data.user.image_url
          });
          
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getUserData();
  }, []);


  const handleUpdateData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if(userData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
    }

    console.log("jainesh : " ,userData);

    const formDataSet = new FormData();
    formDataSet.append('id', userData.id);
    formDataSet.append('status', userData.status);

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/user/update`,
        data: {
          "id": userData.id,
          "status": userData.status,
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      console.log(result);
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate("/user");

      }else{
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 404){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp); // Create a Date object from the timestamp
    const year = date.getFullYear(); // Get the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month and pad with zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with zero if needed
    return `${year}-${month}-${day}`; // Combine them into 'yyyy-mm-dd' format
  }

    return (
      <div className={`${Styles.user}`}>
          <div className={`${Styles.user_heading}`}>
            <h2>User</h2>
          </div>
  
          <div className={`${Styles.userEdit}`}>
    
            <div className={`${Styles.form_heading}`}>
              <h3>Edit User</h3>
              <button onClick={() => navigate("/user")}>
                <RiArrowLeftSLine /> Go To User
              </button>
            </div>
    
            <div className={`${Styles.edit_form}`}>
              <div className={`${Styles.form_grid}`}>
                <div className={`${Styles.form_field}`}>
                  <label>User Name</label>
                  <input type="text" placeholder="Enter User Name" name="first_name" value={userData.first_name} onChange={(e)  => setUserData({...userData, first_name: e.target.value })} readOnly />
                </div>
                <div className={`${Styles.form_field}`}>
                  <label>Email</label>
                  <input type="text" placeholder="Enter User Email" name="email" value={userData.email} onChange={(e)  => setUserData({...userData, email: e.target.value })} readOnly />
                </div>
              </div>
  
  
              <div className={`${Styles.form_grid}`}>
                <div className={`${Styles.form_field}`}>
                  <label>Date of Birth</label>
                  <input type="date" name="dob" value={userData.dob == null ? "N/A" : userData.dob} onChange={(e)  => setUserData({...userData, dob: e.target.value })} readOnly />
                </div>
                <div className={`${Styles.form_field}`}>
                  <label>Phone Number</label>
                  <input type="text" placeholder="Enter Phone Number" value={userData.phone_number == null ? "N/A" : userData.phone_number} onChange={(e)  => setUserData({...userData, phone_number: e.target.value })} readOnly />
                </div>
              </div>
  
  
              <div className={`${Styles.form_grid}`}>
                {/* <div className={`${Styles.form_field}`}>
                  <label>Gender</label>
                  <input type="text" placeholder="Enter Gender" name="gender" value={userData.gender == null ? "N/A" : userData.gender} onChange={(e)  => setUserData({...userData, gender: e.target.value })} readOnly />
                  <select name="gender" value={userData.gender == null ? "" : userData.gender} onChange={(e)  => setUserData({...userData, gender: e.target.value })} disabled >
                      <option value="*">***** Select Gender *****</option>
                      <option value="men">Male</option>
                      <option value="women">Female</option>
                  </select>
                </div> */}
                <div className={`${Styles.form_field}`}>
                  <label>User Profile Picture</label>
                  <div className={`${Styles.customFileInput}`}>
                    <img src={userData.image_url == null ? userprofile : userData.image_url } alt="User Profile" className={`${Styles.user_profile}`}/>
                    {/* <input type="text" placeholder="Choose file..." readOnly  value={userData.image_url} onChange={(e)  => setUserData({...userData, image_url: e.target.value })} /> */}
                    {/* <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle"  /> */}
                  </div>
                </div>
                <div className={`${Styles.form_field}`}>
                  <label>Status</label>
                  <select name="status" value={userData.status} onChange={(e)  => setUserData({...userData, status: e.target.value })} >
                    <option value="">***** Select Status *****</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
  
              {/* <div className={`${Styles.form_grid}`}>
                <div className={`${Styles.form_field}`}>
                  <label>User Profile Picture</label>
                  <div className={`${Styles.customFileInput}`}>
                    <img src={userData.image_url} alt="User Profile" className={`${Styles.user_profile}`}/>
                    <input type="text" placeholder="Choose file..." readOnly  value={userData.image_url} onChange={(e)  => setUserData({...userData, image_url: e.target.value })} />
                    <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle"  />
                  </div>
                </div>
              </div> */}
    
              <div className={`${Styles.buttons}`}>
                <button className={`${Styles.action}`} onClick={handleUpdateData}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
                <button className={`${Styles.cancel}`} onClick={() => navigate("/user")}>Cancel</button>
              </div>
            </div>
        </div>
      </div>
    )
  }

export default UserEdit
