const convertDuration = (second) => {
    // const step1 = second / 60; // 3.15
    // const convert_minute = Math.floor(step1); // 3
    // const convert_second = Math.round(second - (convert_minute * 60)); // 9.5
    // return `${convert_minute}:${convert_second}`;

    const minutes = Math.floor(second / 60);
    const secs = Math.floor(second % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
}

export { convertDuration }
