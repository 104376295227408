import React, { useEffect, useState } from 'react';
import Styles from "../../assets/css/components/admin/dashboard/dashboard.module.scss";
import { BiSolidPlaylist, BiSolidAlbum, BiUser, BiMicrophone } from "react-icons/bi";
import { MdOutlineHeadset } from "react-icons/md";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { IoMusicalNotesSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import ContentLoaderGif from '../../assets/images/contentLoader.gif';

const Dashboard = () => {

  const navigate = useNavigate();
  const [ songData, setSongData] = useState();
  const [ topTrack, setTopTrack] = useState([]);
  const [ topArtistData, setTopArtistData] = useState([]);
  const [ trendingTrack , setTrendingTrack] = useState([]);
  const [contentLoader, setContentLoader] = useState('true');
  // const[ trendingTrackArtistName  , setTrendingTrackArtistName] =useState([]);

  useEffect(() => {

    const token = localStorage.getItem("token");

    const getDashboardData = async () => {
      try {
        const getDashboardList = await axios.get(`${baseUrl}admin/dashboard`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getDashboardList;
        console.log(result);
        if(result.data.status === 200){
          setSongData(result.data.data);
          setTrendingTrack(result.data.data.trending_tracks);
          setTopTrack(result.data.data.top_listening_tracks);
          setTopArtistData(result.data.data.top_artists);
          setContentLoader(result.data.data.length === 0 ? true : false);
          // setTrendingTrackArtistName(result.data.data.trending_tracks.artist.artist_name);
        }

        console.log(result.data.data.trending_tracks.artist.artist_name);
      } catch (error) { 
        console.log("Error : ", error);
      }
    }
    getDashboardData();
  }, []);
    
  return (
    <div className={`${Styles.dashboard}`}>

      <div className={`${Styles.dashboard_heading}`}>
        <h2>Dashboard</h2>
      </div>

      {/* Title & Tracks */}
      <div className={`${Styles.tile_tracks}`}>
        <div className={`${Styles.tile}`}>
          <div className={`${Styles.tile_details} ${Styles.playList}`}>
            <h3>Total Playlists</h3>
            <p>{songData?.total_artist_playlists}</p>
            <BiSolidPlaylist />
          </div>
          <div className={`${Styles.tile_details}`}>
            <h3>Total Albums</h3>
            <p>{songData?.total_albums}</p>
            <BiSolidAlbum />
          </div>
          <div className={`${Styles.tile_details}`} onClick={() => navigate("/artist")}>
            <h3>Total Artist</h3>
            <p>{songData?.total_artist}</p>
            <BiMicrophone />
          </div>
          <div className={`${Styles.tile_details}`} onClick={() => navigate("/user")}>
            <h3>Total Users</h3>
            <p>{songData?.total_users}</p>
            <BiUser />
          </div>
        </div>
      </div>

      {/* Trending Songs */}
      <div className={`${Styles.sang}`}>
        <div className={`${Styles.snag_des}`}>
          <h2>Trending Songs</h2>
          {/* <div className={`${Styles.view_all}`}>
            <a href="#">View All</a>
          </div> */}
          <div className={`${Styles.cards}`}>
            {
              trendingTrack.length === 0 &&
              <div className={`${Styles.dataNotFound}`}>
                {
                  contentLoader &&
                  <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                }
                {
                  !contentLoader &&
                  <p>Data not found</p>
                }
              </div>
            }
            {trendingTrack.map((data) =>(
              <div className={`${Styles.card}`}>
                <img src={data.cover_image} alt="Not Supported" />
                <div className={`${Styles.card_content}`}>
                  <h3>{data.title}</h3>
                  <p className={`${Styles.artist_name}`}>{data?.artist.artist_name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Our Song Table */}
      <div className={`${Styles.music_table}`}>
        <h2>Our Top Listening Songs</h2>

        <div className={`${Styles.table_list}`}>
          {
            topTrack.length === 0 &&
            <div className={`${Styles.dataNotFound}`}>
              {
                contentLoader &&
                <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
              }
              {
                !contentLoader &&
                <p>Data not found</p>
              }
            </div>
          }
          {topTrack.map((item) => (
              <div className={`${Styles.table_row}`}>
                <div className={`${Styles.song_description}`}>
                  <div className={`${Styles.song_image}`}>
                    <img src={item.cover_image} alt="Song Picture" />
                  </div>
                  <div className={`${Styles.song_details}`}>
                      <h3>{item.title}</h3>
                  </div>
                  <div className={`${Styles.song_count}`}>
                    <MdOutlineHeadset />
                    <p>{item.play_count}</p>
                  </div>
                </div>
                
              </div>
            ))}
          </div>
      </div>

      {/* Top Artist */}
      <div className={`${Styles.sang}`}>
        <div className={`${Styles.snag_des}`}>
          <h2>Top Artist</h2>
          {/* <div className={`${Styles.view_all}`}>
            <a href="#">View All</a>
          </div> */}
          {/* <div className={`${Styles.dataNotFound}`}> Data Not Found </div> */}
          <div className={`${Styles.cards}`}>
            {
              topArtistData.length === 0 &&
              <div className={`${Styles.dataNotFound}`}>
                {
                  contentLoader &&
                  <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                }
                {
                  !contentLoader &&
                  <p>Data not found</p>
                }
              </div>
            }
            {topArtistData.map((data) => (
              <div className={`${Styles.card}`} onClick={() => navigate(`/artist/view/${data.id}`)}>
                <img src={data.image_url} alt="artist image" />
                <div className={`${Styles.card_content}`}>
                  <h3>{data.artist_name}</h3>
                  <div className={`${Styles.song_count}`}>
                    <IoMusicalNotesSharp />
                    <p>{data.total_music_tracks}</p>
                  </div> 
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Dashboard
