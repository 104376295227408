import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/language/languageEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LanguageEdit = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading]= useState(false);

  const [ languageData, setLanguageData] = useState({
    id: null,
    name: "",
    status: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    const getLanguageData = async () => {

      try {

        const getLanguageList = await axios.get(`${baseUrl}admin/language/get/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getLanguageList;
        console.log(result);
        if(result.data.status === 200){
        setLanguageData({
            id: result.data.data.id,
            name: result.data.data.name,
            status: result.data.data.status,
          });
          
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getLanguageData();
  }, []);

  const handleUpdateData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if(languageData.name === "" || languageData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
    }

    const formDataSet = new FormData();
    formDataSet.append('id', languageData.id);
    formDataSet.append('name', languageData.name);
    formDataSet.append('status', languageData.status);

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/language/update`,
        data: {
            "id": languageData.id,
            "name": languageData.name,
            "status": languageData.status,
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate("/language");

      }else{
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 404){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }

  return (
    <div className={`${Styles.language}`}>
        <div className={`${Styles.language_heading}`}>
          <h2>Language</h2>
        </div>
  
        <div className={`${Styles.languageEdit}`}>

        <div className={`${Styles.form_heading}`}>
              <h3>Edit Language</h3>
              <button onClick={() => navigate("/language")}>
                <RiArrowLeftSLine /> Go To Language
              </button>
            </div>
    
            <div className={`${Styles.edit_form}`}>
              <div className={`${Styles.form_grid}`}>
                <div className={`${Styles.form_field}`}>
                  <label>Language Name</label>
                  <input type="text" placeholder="Enter Language Name" name="name" value={languageData.name} onChange={(e)  => setLanguageData({...languageData, name: e.target.value })} />
                </div>
                <div className={`${Styles.form_field}`}>
                  <label>Status</label>
                  <select name="status" value={languageData.status} onChange={(e)  => setLanguageData({...languageData, status: e.target.value })} >
                    <option value="">***** Select Status *****</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div className={`${Styles.buttons}`}>
                <button className={`${Styles.action}`} onClick={handleUpdateData}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
                <button className={`${Styles.cancel}`} onClick={() => navigate("/language")}>Cancel</button>
              </div>
            </div>

        </div>
    </div>
  )
}

export default LanguageEdit
