import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clickedPage: 1
}

export const paginationSlice = createSlice({
    name: "pagination",
    initialState: initialState,
    reducers: {
        updatePage: (state, action) => {
            // state.popUpToggle = true;
            // state.popUpDetails = {
            //     heading: action.payload.heading,
            //     message: action.payload.message,
            //     type: action.payload.type
            // }
            
            console.log(action);
        },
        popUpBoxTriggerOff: (state, action) => {
            state.popUpToggle = false;

        }
    }
});

export const { updatePage, popUpBoxTriggerOff } = paginationSlice.actions;

export default paginationSlice.reducer;