import React, { useState } from "react"
import Styles from "../../assets/css/components/admin/channel/channelAdd.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from 'axios';
import { baseUrl } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { imageCompressed } from "../../utils/imageCompress";

const ChannelAdd = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [formData,setFormData] = useState({
    channel_name: "",
    description: "",
    channel_profile_image: null,
    channel_profile_imageName: "",
    channel_banner_image: null,
    channel_banner_imageName: "",
    status: 1
  });

  const [error, setError] = useState({
    channel_name: false,
    description: false,
    status: false,
    channel_profile_image: false,
    channel_banner_image: false
  });

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setFormData({...formData, channel_banner_image: file, channel_banner_imageName: file.name});
    } else {
      setFormData({...formData, channel_banner_image: null, channel_banner_imageName: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setFormData({...formData, channel_profile_image: file, channel_profile_imageName: file.name});
    } else {
      setFormData({...formData, channel_profile_image: null, channel_profile_imageName: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errorData = {
      channel_name: false,
      description: false,
      status: false,
      channel_profile_image: false,
      channel_banner_image: false
    }

    if(formData.channel_name === "") errorData.channel_name = true;
    if(formData.description === "") errorData.description = true;
    if(formData.channel_profile_image === "") errorData.channel_profile_image = true;
    if(formData.channel_banner_image === "") errorData.channel_banner_image = true;
    if(formData.status === "") errorData.status = true;

    if(formData.channel_name === "" || formData.description === "" || formData.channel_profile_image === "" || formData.channel_banner_image === "" || formData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      setLoading(false);
      return;
    }

    if (formData.channel_profile_image === "" || formData.channel_profile_imageName === "") {
      errorData.channel_profile_image = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Profile picture is required."}));
      setLoading(false);
      return;
    }

    if (formData.channel_banner_image === "" || formData.channel_banner_imageName === "") {
      errorData.channel_banner_image = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Banner picture is required."}));
      setLoading(false);
      return;
    }

    setError(errorData);
    if(errorData.channel_name == true || errorData.description == true || errorData.channel_profile_image == true || errorData.channel_banner_image == true || errorData.status == true ){
      setLoading(false);
      return;
    }


    const formDataSet = new FormData();
    formDataSet.append('channel_name', formData.channel_name);
    formDataSet.append('description', formData.description);
    formDataSet.append('channel_profile_image', formData.channel_profile_image);
    formDataSet.append('channel_banner_image', formData.channel_banner_image);
    formDataSet.append('status', formData.status);

    const token = localStorage.getItem("token");

  try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel/create`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate('/channel');
      }else{
        console.log("Playlist Add Failed");
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);
      }
    }catch (error){

      console.log(error);
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error !== ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);
        return;
      }
    }
  };

  return (
    <div className={`${Styles.channel}`}>

      <div className={`${Styles.channelAdd}`}>

        <div className={`${Styles.form_heading}`}>
          <h3>Add New Channel</h3>
          <button onClick={() => navigate("/channel")}>
            <RiArrowLeftSLine /> Go To Channel List
          </button>
        </div>

        <div className={`${Styles.add_form}`}>
          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Name</label>
              <input type="text" placeholder="Enter Channel Name" name="channelName" onChange={(e)=> setFormData({...formData, channel_name: e.target.value})} />
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Description</label>
              <textarea type="text" placeholder="Enter Channel Description" name="description" onChange={(e)=> setFormData({...formData, description: e.target.value})}/>
            </div>
          </div>

          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Profile Picture</label>
              <div className={`${Styles.customFileInput}`}>
                <input type="text" placeholder="Choose file..." value={formData.channel_profile_imageName} />
                <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleProfilePictureChange} />
              </div>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Banner Picture</label>
              <div className={`${Styles.customFileInput}`}>
                <input type="text" placeholder="Choose file..." value={formData.channel_banner_imageName} />
                <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleCoverImageChange} />
              </div>
            </div>
          </div>

          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Status</label>
              <select name="status" onChange={(e)=> setFormData({...formData, status: e.target.value})}>
                <option value="1">Publish</option>
                <option value="0">Unpublish</option>
              </select>
            </div>
          </div>

          <div className={`${Styles.buttons}`}>
            <button className={`${Styles.action}`} onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
            <button className={`${Styles.cancel}`} onClick={() => navigate("/channel")}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelAdd
