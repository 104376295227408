import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/business/business.module.scss";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import { baseUrl } from '../../utils/constant';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Business = () => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    company_name: "",
    company_address: "",
    email: "",
    mobile: "",
    website_url: "",
    map_api_key: "",
    facebook_url: "",
    instagram_url: "",
    x_url: ""
  });

  const [errors, setErrors] = useState({
    company_name: false,
    company_address: false,
    email: false,
    mobile: false,
    website_url: false,
    map_api_key: false,
    facebook_url: false,
    instagram_url: false,
    x_url: false
  });

  const [loading, setLoading]= useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isInputNumber = (evt) => {
    var ch = String.fromCharCode(evt.which);
    if(!(/[0-9]/.test(ch))){
        evt.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const errorData = {
        company_name: false,
        company_address: false,
        email: false,
        mobile: false,
        website_url: false,
        map_api_key: false,
        facebook_url: false,
        instagram_url: false,
        x_url: false
      }

      if(formData.company_name === "") errorData.company_name = true;
      if(formData.company_address === "") errorData.company_address = true;
      if(formData.email === "") errorData.email = true;
      if(formData.mobile === "") errorData.mobile = true;
      if(formData.website_url === "") errorData.website_url = true;
      if(formData.map_api_key === "") errorData.map_api_key = true;
      if(formData.facebook_url === "") errorData.facebook_url = true;
      if(formData.instagram_url === "") errorData.instagram_url = true;
      if(formData.x_url === "") errorData.x_url = true;

      if(formData.company_name === "" || formData.company_address === "" || formData.email === "" || formData.mobile === "" || formData.website_url === "" || formData.map_api_key === "" || formData.facebook_url === "" || formData.instagram_url === "" || formData.x_url === ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      }

      setErrors(errorData);

      const token = localStorage.getItem("token");
      const formDataSet = new FormData();
      formDataSet.append('company_name', formData.company_name);
      formDataSet.append('company_address', formData.company_address);
      formDataSet.append('email', formData.email);

      formDataSet.append('mobile', formData.mobile);
      formDataSet.append('website_url', formData.website_url);
      formDataSet.append('map_api_key', formData.map_api_key);

      formDataSet.append('facebook_url', formData.facebook_url);
      formDataSet.append('instagram_url', formData.instagram_url);
      formDataSet.append('x_url', formData.x_url);

      try{
        const data = await axios({
          method: 'POST',
          url: `${baseUrl}admin/update-business-settings`,
          data: {
            company_name : formData.company_name,
            company_address : formData.company_address,
            email : formData.email,
            mobile : formData.mobile,
            website_url : formData.website_url,
            map_api_key : formData.map_api_key,
            facebook_url : formData.facebook_url,
            instagram_url : formData.instagram_url,
            x_url : formData.x_url,
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = data;
        if(result.data.status === 200){
          setLoading(false);
          dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        }else{
          console.log("Update Failed");
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
          setLoading(false);
        }
      }catch (error){

        console.log(error);
        
        if(error.response.data.status === 422){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }

        if(error.response.data.status === 404){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }
      }
      
    };

  // const validateForm = () => {
  //   const newErrors = {};
  //   const urlPattern = new RegExp('^(https?:\\/\\/)?(www\\.)?([\\w-]+)\\.([a-z]{2,6})(\\/\\w*)*$');
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!formData.company_name) newErrors.company_name = "Company Name is required.";
  //   if (!formData.company_address) newErrors.company_address = "Address is required.";
  //   if (!emailPattern.test(formData.email)) newErrors.email = "Invalid email format.";
  //   if (!formData.mobile) newErrors.mobile = "Phone Number is required.";
  //   if (!urlPattern.test(formData.website_url)) newErrors.website_url = "Invalid URL format.";
  //   if (!formData.map_api_key) newErrors.map_api_key = "Map API Key is required.";
  //   if (!urlPattern.test(formData.facebook_url)) newErrors.facebook_url = "Invalid URL format.";
  //   if (!urlPattern.test(formData.instagram_url)) newErrors.instagram_url = "Invalid URL format.";
  //   if (!urlPattern.test(formData.x_url)) newErrors.x_url = "Invalid URL format.";

  //   return newErrors;
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  useEffect(() => {

    const getToken = localStorage.getItem('token');

    async function getBusinessData(){
      // const {id, name, image} = JSON.parse(localStorage.getItem("user_data"));

      const getData = await axios.get(`${baseUrl}admin/get-business-settings`, {
        headers: {
          'Authorization': 'Bearer ' + getToken
        }
      });

      const result = getData;
      if(result.status != 200){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Artist data not found."}));
      }

      if(result.status === 200){
        console.log(result.data.data);
        const setData = {
          company_name: result.data.data.company_name,
          company_address: result.data.data.company_address,
          email: result.data.data.email,
          mobile: result.data.data.mobile,
          website_url: result.data.data.website_url,
          map_api_key: result.data.data.map_api_key,
          facebook_url: result.data.data.facebook_url === null ? "" : result.data.data.facebook_url,
          instagram_url: result.data.data.instagram_url === null ? "" : result.data.data.instagram_url,
          x_url: result.data.data.x_url === null ? "" : result.data.data.x_url
        };
        setFormData(setData);
      }
    }
    getBusinessData();

    }, []);

    console.log("FormData: ",formData);

  return (
    <div className={`${Styles.work}`}>

      <div className={`${Styles.work_heading}`}>
        <h2>Business</h2>
      </div>

      <div className={`${Styles.work_nav}`}>

        <div className={`${Styles.form_heading}`}>
          <h3>Business Settings</h3>
        </div>
  
        <form className={`${Styles.add_form}`}>

          <div className={`${Styles.company_details}`}>
            <h3>Company Details</h3>
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Company Name</label>
                <input type="text" placeholder="Enter Company Name" name="company_name" value={formData.company_name} onChange={handleInputChange} />
                {errors.company_name && <span>{errors.company_name}</span>}
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Enter Company Address</label>
                <textarea placeholder="Enter Company Address" name="company_address" value={formData.company_address} onChange={handleInputChange} />
                {errors.company_address && <span>{errors.company_address}</span>}
              </div>
            </div>

            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Email</label>
                <input type="text" placeholder="Enter Email" name="email" value={formData.email} onChange={handleInputChange} />
                {errors.email && <span>{errors.email}</span>}
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Phone Number</label>
                <input type="text" placeholder="Enter Phone Number" onKeyPress={(evt) => isInputNumber(evt)} name="mobile" value={formData.mobile} onChange={handleInputChange} />
                {errors.mobile && <span>{errors.mobile}</span>}
              </div>
            </div>

            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Website URL</label>
                <input type="text" placeholder="Enter Website URL" name="website_url" value={formData.website_url} onChange={handleInputChange} />
                {errors.website_url && <span>{errors.website_url}</span>}
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Map API Key</label>
                <input type="text" placeholder="Enter Map API Key" name="map_api_key" value={formData.map_api_key} onChange={handleInputChange} />
                {errors.map_api_key && <span>{errors.map_api_key}</span>}
              </div>
            </div>
          </div>

          <div className={`${Styles.social_media}`}>
            <h3>Social Media</h3>
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Facebook URL</label>
                <input type="text" placeholder="Enter Facebook URL" name="facebook_url" value={formData.facebook_url} onChange={handleInputChange} />
                {errors.facebook_url && <span>{errors.facebook_url}</span>}
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Instagram URL</label>
                <input type="text" placeholder="Enter Instagram URL" name="instagram_url" value={formData.instagram_url} onChange={handleInputChange} />
                {errors.instagram_url && <span>{errors.instagram_url}</span>}
              </div>
            </div>

            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>X URL</label>
                <input type="text" placeholder="Enter X URL" name="x_url" value={formData.x_url} onChange={handleInputChange} />
                {errors.x_url && <span>{errors.x_url}</span>}
              </div>
            </div>
          </div>
  
          <div className={`${Styles.button}`}>
            <button className={`${Styles.action}`} onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
          </div>
        </form>

      </div>
    </div>
  );
}

export default Business;
