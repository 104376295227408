import { configureStore, combineReducers } from "@reduxjs/toolkit";
import toggleSlice from "../features/navbar/toggleSlice";
import popUpSlice from "../features/message/popUpSlice";
import paginationSlice from "../features/pagination/paginationSlice";

const rootReducer = combineReducers({
    toggle: toggleSlice,
    popUpToggle: popUpSlice,
    pagination: paginationSlice,
})

const store = configureStore({
    reducer: rootReducer
});

export default store;