import React from "react";
import Styles from "../assets/css/layouts/sidebar.module.scss";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { ImCancelCircle } from "react-icons/im";
import { sidebarOn } from "../features/navbar/toggleSlice";
// import {artistSidebar, adminSidebar} from "../utils/sidebarData";
import { adminSidebar } from "../utils/sidebarData";

const Sidebar = (props) => {
  const toggleValue = useSelector(state => state.toggle.toggle);
  const dispatch = useDispatch();
  // let sidebarData = [];

  // if(props.sidebarData === "admin"){
  //   sidebarData = adminSidebar;
  // }

  // if(props.sidebarData === "artist"){
  //   sidebarData = artistSidebar;
  // }

  return (
    <div className={`${Styles.sidebar} ${toggleValue ? Styles.active : ""}`}>

      {/* Sidebar Logo */}
      <div className={`${Styles.logo} ${toggleValue ? Styles.active : ""}`}>
        <img src={Logo} alt="Logo" />
        <ImCancelCircle onClick={() => dispatch(sidebarOn())} />
      </div>

      {/* Sidebar Navigation Menu */}
      <div className={`${Styles.menu}`}> 

        {adminSidebar.map((sidebarData, index) => (
          <NavLink key={index} to={sidebarData.navigation} className={({ isActive }) => `${Styles.submenu} ${isActive ? Styles.active : ""}`} >
            <div className={Styles.sidebarIcon}>
              {sidebarData.icon}
            </div>
            <p className={`${toggleValue ? Styles.active : ""}`}>{sidebarData.label}</p>
          </NavLink>
        ))}

      </div>
    </div>
  );
};

export default Sidebar;
