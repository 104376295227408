import React from "react";
import Styles from "../../assets/css/components/admin/adminUser/userView.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import ProfileLogo from "../../assets/images/Admin/User-profile.png";

const UserView = () => {

    const navigate = useNavigate();

  return (
    <div className={`${Styles.user}`}>
        <div className={`${Styles.user_heading}`}>
            <h2>User</h2>
        </div>

        <div className={`${Styles.user_view}`}>

            <div className={`${Styles.detail_heading}`}>
              <h3>User Details</h3>
              <button onClick={() => navigate("/user")}>
                <RiArrowLeftSLine /> Go To User
              </button>
            </div>

            <div className={`${Styles.details}`}>
                <div className={`${Styles.img_name}`}>
                    <div className={`${Styles.user_profile}`}>
                        <img src={ProfileLogo} alt="Track Picture1" />
                    </div>
                    <div className={`${Styles.user_name}`}>
                        <h3>Aryana Sayeed</h3>
                        <p>Artist</p>
                    </div>
                </div>
                {/* <div>
                    <div>
                        box-1
                    </div>
                    <div>
                        box-2
                    </div>
                </div> */}
            </div>

            <div className={`${Styles.user_info}`}>

            </div>
        </div>
    </div>
  )
}

export default UserView
