import imageCompression from 'browser-image-compression';

const imageCompressed = async (originalImage) => {
    const imageFile = originalImage //image file
    console.log('Original file size:', imageFile.size / 1024 / 1024, 'MB');
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: 'image/jpeg'
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const base64String = await imageCompression.getDataUrlFromFile(imageFile);
      console.log('Compressed file size:', compressedFile.size / 1024 / 1024, 'MB');
    //   console.log("compressedFile : ",compressedFile);
    //   imageCompression.getDataUrlFromFile("file : ", File);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing the image:', error);
      return error;
    }
};

export {imageCompressed}