import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthCheck = (props) => {

    const Component = props.component;
    const navigate = useNavigate();

    useEffect(() => {
        if(!localStorage.getItem("token")){
            navigate("/login");
        }
    });

  return <Component />
}

export default AuthCheck
