import React from "react";
import Styles from "../../assets/css/components/admin/adminUser/userAdd.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

const UserAdd = () => {

    const navigate = useNavigate();

    return (
    <div className={`${Styles.user}`}>
        <div className={`${Styles.user_heading}`}>
          <h2>User</h2>
        </div>

      <div className={`${Styles.userAdd}`}>
  
          <div className={`${Styles.form_heading}`}>
            <h3>Add New User</h3>
            <button onClick={() => navigate("/user")}>
              <RiArrowLeftSLine /> Go To User List
            </button>
          </div>
  
          <div className={`${Styles.add_form}`}>
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>User Name</label>
                <input type="text" placeholder="Enter User Name" />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Email</label>
                <input type="text" placeholder="Enter User Email" name="email"/>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Date of Birth</label>
                <input type="date" name="dob"/>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Phone Number</label>
                <input type="text" placeholder="Enter Phone Number"/>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Gender</label>
                <select name="gender">
                    <option value="">***** Select Gender *****</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Status</label>
                <select name="status">
                  <option value="*">***** Select Status *****</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Password</label>
                <input type="text" placeholder="Enter Password" />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Confirm Password</label>
                <input type="text" placeholder="Enter Confirm Password"/>
              </div>
            </div>
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>User Profile Name</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" placeholder="Choose file..." readOnly />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" />
                </div>
              </div>
            </div>
  
            <div className={`${Styles.buttons}`}>
              <button className={`${Styles.action}`}>Create</button>
              <button className={`${Styles.cancel}`} onClick={() => navigate("/user")}>Cancel</button>
            </div>
          </div>
      </div>
    </div>
    );
}

export default UserAdd
