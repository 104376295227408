import { MdOutlineQueueMusic, MdOutlineTrackChanges, MdDashboard, MdPrivacyTip, MdDescription, MdSettings, MdRadio, MdHelp } from "react-icons/md";
import { FaClipboardList, FaUser, FaIdCard, FaLanguage} from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { BiSolidCategory } from "react-icons/bi";
// import { LiaUsersCogSolid } from "react-icons/lia";
// import { GrChannel, GrSupport } from "react-icons/gr";
// import bsSetting  from "../assets/images/Admin/Sideabr/business.png";
// import terms  from "../assets/images/Admin/Sideabr/t&c.png";

// const artistSidebar = [
//     {
//         navigation: "/artist/dashboard",
//         icon: <MdDashboard />,
//         label: "Dashboard",
//     },
//     {
//         navigation: "/artist/track",
//         icon: <MdOutlineTrackChanges />,
//         label: "Track Manage",
//     },
//     {
//         navigation: "/artist/playlist",
//         icon: <MdOutlineQueueMusic />,
//         label: "Playlist Manage",
//     },
//     {
//         navigation: "/artist/album",
//         icon: <FaClipboardList />,
//         label: "Album Manage",
//     },
//     {
//         navigation: "/artist/profile",
//         icon: <FaUser />,
//         label: "Profile Manage",
//     }
// ]

const adminSidebar = [
    {
        navigation: "/dashboard",
        icon: <MdDashboard />,
        label: "Dashboard",
    },
    {
        navigation: "/user",
        icon: <FaUser />,
        label: "User",
    },
    {
        navigation: "/artist",
        icon: <FaIdCard />,
        label: "Artist",
    },
    {
        navigation: "/channel",
        icon: <MdRadio  />,
        label: "Channel",
    },
    {
        navigation: "/category",
        icon: <BiSolidCategory  />,
        label: "Category",
    },
    {
        navigation: "/language",
        icon: <FaLanguage  />,
        label: "Language",
    },
    {
        navigation: "/support",
        icon: <MdHelp />,
        label: "Support",
    },
    {
        navigation: "/advertisement",
        icon: <RiAdvertisementFill />,
        label: "Advertisement",
    },
    // {
    //     navigation: "/privacy",
    //     icon: <MdPrivacyTip />,
    //     label: "Privacy & Policy",
    // },
    // {
    //     navigation: "/terms",
    //     icon: <MdDescription />,
    //     label: "Terms & Conditions",
    // },
    {
        navigation: "/business",
        icon: <MdSettings />,
        label: "Business Setting",
    }
]

// export {artistSidebar, adminSidebar};
export { adminSidebar};
