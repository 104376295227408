import React, { useState } from "react";
import Styles from "../../assets/css/auth/admin/login.module.scss";
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/logo/Logo.png";
import { FaRegEye, FaRegEyeSlash} from "react-icons/fa";
import { baseUrl } from "../../utils/constant";
import axios from "axios";
import { useDispatch } from "react-redux";
import PopUpMessage from "../../utils/errors/PopUpMessage";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LogIn = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    email: false,
    password: false
  });

  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try{
      const data = await axios({
        method: 'post',
        url: `${baseUrl}admin/login`,
        data: {
          email: email,
          password: password
        }
      });

      const result = data;
      console.log("Result : ", result);
      console.log("Data : ", data);

      if(result.data.status === 200){

        let userData = {
          id: result.data.data.id,
          name: result.data.data.first_name
          // image: result.data.data.image_url
        };

        userData = JSON.stringify(userData);

        localStorage.setItem("token", result.data.token);
        localStorage.setItem("user_data", userData);

        console.log("Login success.");
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate('/dashboard', {replace: true});

      }else{
        console.log("Authentication Failed.");
      }
    }catch (error){
      console.log("Login Page Error");
      setError({email: false, password: false});
      setLoading(false);

      if(error.response?.data.status === 404 || error.response?.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setError({email: true, password: true});
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        return;
      }

      if("email" in error.response.data.errors){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.email[0]}));
        setError({...error, email: true});
        return;
      }

      if("password" in error.response.data.errors){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.password[0]}));
        setError({...error, password: true});
        return;
      }
    }
  };

  return (
    <div className={`${Styles.loginMain}`}>

      {/* login background */}
      <div className={`${Styles.loginBg}`}>

        {/* Logo */}
        <div className={`${Styles.logo}`}>
          <img src={Logo} alt="not supported" />
        </div>

        {/* Login Form */}
        <div className={`${Styles.loginForm}`}>
          <div className={`${Styles.email} ${Styles.inputField}`}>
            <label>Email</label>
            <input type="text" placeholder='Enter Your Email' value={email} onChange={(e)=> setEmail(e.target.value)} style={{borderColor: `${error.email ? "red" : ""}`}} />
            {/* {errors?.email && <p className={`${Styles.error}`}>{errors?.email}</p>} */}
          </div>
          <div className={`${Styles.password} ${Styles.inputField}`}>
            <label>Password</label>
            <div>
              <input type={showPass ? "text" : "password"} placeholder='Enter Your Password' value={password} onChange={(e)=> setPassword(e.target.value)} style={{borderColor: `${error.password ? "red" : ""}`}} />
              {showPass && <FaRegEye onClick={() => setShowPass(false)} />}
              {!showPass && <FaRegEyeSlash onClick={() => setShowPass(true)} />}
            </div>
            {/* {errors?.password && <p className={`${Styles.error}`}>{errors?.password}</p>} */}
          </div>
          <div className={`${Styles.forgotPassword}`}>
            <Link to="/resetPassword">Forgot Password?</Link>
          </div>
          <div className={`${Styles.button}`}>
            <button onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Login"}</button>
          </div>
        </div>
        <PopUpMessage />
      </div>
    </div>
  )
}

export default LogIn