import React from "react";
import Styles from "../../assets/css/components/admin/privacy&policy/privacyEdit.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

const PrivacyPolicyEdit = () => {

    const navigate = useNavigate();

  return (
    <div className={`${Styles.pp}`}>

      <div className={`${Styles.pp_heading}`}>
        <h2>Privacy & Policy</h2>
      </div>

      <div className={`${Styles.pp_description}`}>
        <div className={`${Styles.pp_edit}`}>
            <div className={`${Styles.form_heading}`}>
              <h3>Edit Privacy & Policy</h3>
              <button onClick={() => navigate("/privacy")}>
                <RiArrowLeftSLine /> Go To Privacy & Policy List
              </button>
            </div>

            <div className={`${Styles.edit_list}`}>
                <div className={`${Styles.list_grid}`}>
                    <div className={`${Styles.list_field}`}>
                        <textarea type="text" placeholder="Enter Description" name="privacy"/>
                    </div>
                </div>
            </div>

            <div className={`${Styles.pp_btn}`}>
                <button className={`${Styles.create}`}>Update</button>
                <button className={`${Styles.cancel}`} onClick={() => navigate("/privacy")}>Cancel</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyEdit
