import React, { useEffect, useState } from "react";
import Styles from "../../../assets/css/components/admin/channel/playlist/playlistView.module.scss";
import { RiArrowLeftSLine } from "react-icons/ri";
import { json, useNavigate, useParams } from "react-router-dom";
import { IoRemoveOutline } from "react-icons/io5";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../../features/message/popUpSlice";
import { baseUrl } from "../../../utils/constant";
import axios from "axios";
import { convertDuration } from "../../../utils/convertFunctions";
import { FaUsers } from "react-icons/fa";
import { LuClock7 } from "react-icons/lu";
import { FaLink } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import DeleteIllustration from "../../../assets/images/deleteIllustrator.png";

const PlaylistView = () => {

  const navigate = useNavigate();
  const { channelId, playlistId } = useParams();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  const [addTrackModal, setAddTrackModal] = useState(false);
  const [playlistTracks, setPlaylistTracks] = useState([]);
  const [channelPlaylist, setChannelPlaylist] = useState([]);
  const [linkSong, setLinkSong] = useState(false);
  const [linkSongSearch, setLinkSongSearch] = useState([]);
  const [unlinkSongs, setUnlinkSongs] = useState();
  const [addSongRefresh, setAddSongRefresh] = useState(0);
  const [deletePlaylistDrop, setDeletePlaylistDrop] = useState(false);
  const [dropDownChannelManage, setDropDownPlaylistManage] = useState({
    id: 0,
    open: false
  });

  const [editPlaylistModule, setEditPlaylistModule] = useState();

  // const [artistIds, setArtistIds] = useState([]);
  const [trackSearch, setTrackSearch] = useState([]);
  // const [allArtistList, setAllArtistList] = useState([]);

  // const combinedSearch = `${trackSearch} ${linkSongSearch}`; 

  // const [channelDetails, setChannelDetails] = useState({
  //   title: "Akash",
  //   image: "",
  //   status: "Publish",
  //   totalTrack: 0,
  //   totalPlaylist: 0
  // });

  // const [addTrackData, setAddTrackData] = useState({
  //   title: "",
  //   description: "",
  //   language_id: "",
  //   category_id: "",
  //   status: 1,
  //   search: "",
  //   track_file: null,
  //   trackFileName: "",
  //   coverImage: null,
  //   coverImageName: "",
  //   duration: 0
  // });

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [editPlaylistData, setEditPlaylistData] = useState({
    title: "",
    description: "",
    status: "",
    language_id: "",
    category_id: "",
    release_date: "",
    channel_id: "",
    id: playlistId,
    cover_image: null,
    cover_image_name: "",
    profile_image: null,
    profile_image_name: ""
  });
  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    status: false,
    language_id: false,
    category_id: false,
    release_date: false,
    channel_id: false,
    id: false,
    cover_image: false,
    profile_image: false
  });

  const handleInputChange = (e) => {
    setEditPlaylistData({ ...editPlaylistData, [e.targte.name]: e.target.value });
  };

  const handleDeleteTrack = async (id) => {
    console.log("Id : ", id)

    const token = localStorage.getItem('token');
    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel-playlist/remove-music/${channelId}/${playlistId}/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setRefresh(refresh + 1);
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  }

  const handleDeletePlaylist = async () => {
    const token = localStorage.getItem('token');
    try {
      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/channel-playlist/delete/${channelId}/${playlistId}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        navigate(`/channel/view/${channelId}`);
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  }

  const handlePlaylistProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setEditPlaylistData({ ...editPlaylistData, profile_image: file, profile_image_name: file.name });
    } else {
      setEditPlaylistData({ ...editPlaylistData, profile_image: null, profile_image_name: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handlePlaylistCoverPictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setEditPlaylistData({ ...editPlaylistData, cover_image: file, cover_image_name: file.name });
    } else {
      setEditPlaylistData({ ...editPlaylistData, cover_image: null, cover_image_name: "" });
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
    }
  };

  const handleEditPlaylist = async () => {
    setEditPlaylistModule(true);
    setAddTrackModal(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${baseUrl}admin/channel-playlist/get/${playlistId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log("EditData:", response);

      if (response.status === 200) {
        const EditData = response.data.data;
        console.log("CHeck : ", EditData)
        setEditPlaylistData({
          title: EditData.title || "",
          description: EditData.description || "",
          status: EditData.status ? "1" : "0",
          language_id: EditData.language_id || "",
          category_id: EditData.category_id || "",
          release_date: formatDate(EditData.release_date),
          channel_id: EditData.channel_id,
          id: playlistId,
          cover_image_name: EditData.cover_image ? EditData.cover_image.split('/').pop() : "",
          profile_image_name: EditData.profile_image ? EditData.profile_image.split('/').pop() : ""
        });
        // setChannelPlaylist(data);
      }
    } catch (error) {
      console.error("Error fetching playlist data:", error);
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
    }
  };

  const handleEditPlaylistSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    const formDataSet = new FormData();

    // Append data to FormData
    formDataSet.append('title', editPlaylistData.title);
    formDataSet.append('description', editPlaylistData.description);
    formDataSet.append('status', editPlaylistData.status);
    formDataSet.append('language_id', editPlaylistData.language_id);
    formDataSet.append('category_id', editPlaylistData.category_id);
    formDataSet.append('release_date', editPlaylistData.release_date);
    formDataSet.append('channel_id', editPlaylistData.channel_id);
    formDataSet.append('id', editPlaylistData.id);
    if (editPlaylistData.cover_image_name !== "") {
      formDataSet.append('cover_image', editPlaylistData.cover_image);
    }
    if (editPlaylistData.profile_image_name !== "") {
      formDataSet.append('profile_image', editPlaylistData.profile_image);
    }

    try {
      const response = await axios.post(`${baseUrl}admin/update-channel-playlist`, formDataSet, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: response.data.message }));
        setEditPlaylistModule(false);
        setAddTrackModal(false);
        setLoading(false);
        navigate(`/channel/${channelId}/playlistView/${playlistId}`);
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! something went wrong" }));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating playlist:", error);
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! something went wrong" }));
      setLoading(false);
    }
  };

  useEffect(() => {
    const getAllRequiredData = async () => {

      const token = localStorage.getItem("token");
      try {
        const getCategoryData = await axios.get(`${baseUrl}admin/category/list?page=1&limit=5000&form&to&status=1&search&sort=desc`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        if (getCategoryData.status === 200) {
          setCategory(getCategoryData.data.data.getCategory);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }


      try {
        const getLanguageData = await axios.get(`${baseUrl}admin/language/list?status=1`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        if (getLanguageData.status === 200) {
          setLanguage(getLanguageData.data.data.languages);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        }
      }

    }
    getAllRequiredData();
  }, [refresh]);

  // const artistSearch = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const getArtistData = await axios.get(`${baseUrl}admin/artist/list?sort=z_to_a&limit=5000&search=${addTrackData.search}`, {
  //       headers: {
  //         'Authorization': 'Bearer ' + token
  //       }
  //     });
  //     console.log("Result : ", getArtistData);
  //     if (getArtistData.status === 200) {
  //       setAllArtistList(getArtistData.data.data.artist);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error) {
  //       dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
  //     }
  //   }
  // }

  // const handleArtistId = async (id) => {
  //   if (artistIds.includes(id)) {
  //     const filter = artistIds.filter((data) => {
  //       return data != id;
  //     });
  //     setArtistIds(filter);
  //   }

  //   if (!artistIds.includes(id)) {
  //     setArtistIds([...artistIds, id]);
  //   }
  // }

  // const handleTrackFile = (e) => {
  //   const file = e.target.files[0];
  //   console.log(file);
  //   console.log("outside");
  //   if (file && (file.type === 'audio/mpeg')) {
  //     console.log("inside");
  //     const audio = new Audio(URL.createObjectURL(file));
  //     audio.addEventListener("loadedmetadata", () => {
  //       setAddTrackData({ ...addTrackData, track_file: file, duration: audio.duration, trackFileName: file.name });
  //     });
  //   } else {
  //     setAddTrackData({ ...addTrackData, track_file: "", duration: 0, trackFileName: "" });
  //     dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid audio file (mp3)." }));
  //   }
  // };

  // const handleCoverPictureChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
  //     setAddTrackData({ ...addTrackData, coverImage: file, coverImageName: file.name });
  //   } else {
  //     setAddTrackData({ ...addTrackData, coverImage: null, coverImageName: "" });
  //     dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)." }));
  //   }
  // };

  // const handleAddNormalTrackSubmit = async (e) => {

  //   e.preventDefault();
  //   setLoading(true);

  //   if (addTrackData.track_file === "" || addTrackData.trackFileName === "") {
  //     dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Audio file is required." }));
  //     setLoading(false);
  //     return;
  //   }

  //   if (addTrackData.coverImage === "" || addTrackData.coverImageName === "") {
  //     dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Cover image is required." }));
  //     setLoading(false);
  //     return;
  //   }

  //   if (addTrackData.title === "" || addTrackData.description === "" || addTrackData.language_id === "" || addTrackData.category_id === "" || addTrackData.status === "" || addTrackData.duration === "") {
  //     dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please fill all the fields." }));
  //     setLoading(false);
  //     return;
  //   }

  //   const formDataSet = new FormData();
  //   formDataSet.append('channel_id', channelId);
  //   formDataSet.append('title', addTrackData.title);
  //   formDataSet.append('description', addTrackData.description);
  //   formDataSet.append('track_duration', addTrackData.duration);
  //   formDataSet.append('language_id', addTrackData.language_id);
  //   formDataSet.append('category_id', addTrackData.category_id);
  //   formDataSet.append('status', addTrackData.status);
  //   formDataSet.append('music_track', addTrackData.track_file);
  //   formDataSet.append('cover_image', addTrackData.coverImage);
  //   formDataSet.append('artist_ids', artistIds);
  //   // formDataSet.append('artist_ids', JSON.stringify(artistIds)); 

  //   // const artistIds = [];
  //   // const bodyFormData = new FormData();
  //   // artistIds.forEach((item) => {
  //   //   formDataSet.append('artist_ids[]', item);
  //   // });

  //   const token = localStorage.getItem("token");

  //   try {

  //     const data = await axios({
  //       method: 'POST',
  //       url: `${baseUrl}admin/channel-playlist/add-tracks`,
  //       data: formDataSet,
  //       headers: {
  //         'Authorization': 'Bearer ' + token
  //       }
  //     });

  //     const result = data;
  //     if (result.data.status === 200) {
  //       setLoading(false);
  //       dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
  //       setAddTrackModal(false);
  //       setRefresh(refresh + 1);
  //       setAddTrackData({
  //         title: "",
  //         description: "",
  //         language_id: 1,
  //         category_id: 1,
  //         status: 1,
  //         search: "",
  //         track_file: null,
  //         trackFileName: "",
  //         coverImage: null,
  //         coverImageName: "",
  //         duration: 0
  //       });
  //     } else {
  //       console.log("Track Add Failed");
  //       dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
  //       setLoading(false);
  //     }
  //   } catch (error) {

  //     console.log(error);
  //     if (error.response.data.status === 422) {
  //       dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
  //       setLoading(false);
  //       return;
  //     }

  //     if (error !== "") {
  //       dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
  //       setLoading(false);
  //       return;
  //     }
  //   }
  // };

  const AddToList = (id) => {
    setPlaylistTracks([...playlistTracks, { music: { id } }]);
    setUnlinkSongs(unlinkSongs.filter(song => song.id !== id));

    const addData = async () => {
      const token = localStorage.getItem("token");

      try {

        const add = await axios({
          method: 'POST',
          url: `${baseUrl}admin/channel-playlist/add-tracks`,
          data: {
            "tracks_ids": [
              id
            ],
            "channel_id": channelId,
            "playlist_id": playlistId
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        if (add.status === 200) {
          console.log("-------------------");
          console.log(add.data.data);
          setAddSongRefresh(addSongRefresh + 1);
          setLinkSong(false);
          dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: "Song add successfully." }));
          // setChannelPlaylist(getPlaylistData.data.data);
          // setPlaylistTracks(getPlaylistData.data.data.channel_playlist_tracks);
        }
      } catch (error) {
        console.error("Error fetching playlist data:", error);
        // dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "This song already in playlist." }));

      } finally {
        setLoading(false);
      }
    }
    addData(refresh);
  }

  useEffect(() => {
    const getAllRequiredData = async () => {
      const token = localStorage.getItem("token");

      try {
        const getPlaylistData = await axios.get(`${baseUrl}admin/channel-playlist/get/${playlistId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        if (getPlaylistData.status === 200) {
          console.log(getPlaylistData.data.data);
          setChannelPlaylist(getPlaylistData.data.data);
          setPlaylistTracks(getPlaylistData.data.data.channel_playlist_tracks);
        }
      } catch (error) {
        console.error("Error fetching playlist data:", error);
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
      } finally {
        setLoading(false);
      }
    };

    getAllRequiredData();
  }, [refresh, addSongRefresh]);

  useEffect(() => {
    const getSearchList = async () => {
      const token = localStorage.getItem("token");

      try {
        const getPlaylistData = await axios.get(`${baseUrl}admin/channel/list-tracks/${channelId}?search=${trackSearch}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        if (getPlaylistData.status === 200) {
          console.log("-------------------");
          console.log(getPlaylistData.data.data);
          setUnlinkSongs(getPlaylistData.data.data);
          // setChannelPlaylist(getPlaylistData.data.data);
          // setPlaylistTracks(getPlaylistData.data.data.channel_playlist_tracks);
        }
      } catch (error) {
        console.error("Error fetching playlist data:", error);
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops!! Something went wrong." }));
      } finally {
        setLoading(false);
      }
    }
    getSearchList();
  }, [linkSongSearch, addSongRefresh, trackSearch]);

  return (
    <div className={`${Styles.playlist}`}>

      {
        deletePlaylistDrop && (
          <div className={`${Styles.deletePlaylist}`}>
            <div className={`${Styles.innerDeletePlaylist}`}>
              <div className={`${Styles.heading}`}>
                <p>Do you really want to delete this Playlist?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                <button onClick={handleDeletePlaylist}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Playlist"}</button>
                <button onClick={() => { setDeletePlaylistDrop(false); setDropDownPlaylistManage({ id: 0, open: false }); }}>Skip & Continue</button>
              </div>
            </div>
          </div>
        )
      }

      <div className={`${Styles.playlist_nav}`}>

        {/* Playlist Details */}
        <div className={`${Styles.track}`}>
          <div className={`${Styles.playlist_info}`}>
            <div className={`${Styles.playlist_navigate}`}>
              <button onClick={() => navigate(`/channel/view/${channelId}`)}>
                {/* <RiArrowLeftSLine /> Back To Playlist */}
                <RiArrowLeftSLine /> Back To Track
              </button>
            </div>

            {/* Name || Active || Deactive */}
            <div className={`${Styles.playlist_details}`}>
              <div className={`${Styles.playlist_name_status}`}>
                <h2>{channelPlaylist.title}</h2>
                <p>{channelPlaylist.status}</p>
              </div>
            </div>

            {/* Playlist Row Data */}
            <div className={`${Styles.playlist_counts}`}>
              <div className={`${Styles.playlist_songs}`}>
                <p>Songs</p>
                <IoRemoveOutline />
                <p>{channelPlaylist.channel_playlist_tracks?.length}</p>
              </div>
            </div>

            <div className={`${Styles.buttons}`}>

              {/* Playlist Delete Button */}
              <div className={`${Styles.delete}`}>
                <MdDelete className={`${Styles.delete_Svg}`} />
                <button className={`${Styles.delete_btn}`} onClick={() => { setDeletePlaylistDrop(!deletePlaylistDrop); }}>Delete</button>
              </div>

              {/* Playlist Edit Button */}
              <div className={`${Styles.edit}`}>
                <FiEdit className={`${Styles.edit_svg}`} />
                <button className={`${Styles.edit_btn}`} onClick={handleEditPlaylist}>Edit</button>
              </div>
            </div>
          </div>
          <div className={`${Styles.channel_image}`}>
            <img src={channelPlaylist.cover_image} alt="not supported" />
          </div>
        </div>
      </div>


      {/* Playlist Table */}
      <div className={`${Styles.playlist_data_tracks}`}>
        {/* Playlist Edit Form */}
        {
          editPlaylistModule && (
            <div className={`${Styles.edit_normal_playlist}`}>

              <div className={`${Styles.playlist_heading}`}>
                <h2>Edit Playlist</h2>
              </div>

              <div className={`${Styles.playlistEdit}`}>
                <div className={`${Styles.edit_form}`}>
                  <div className={`${Styles.form_grid}`}>
                    <div className={`${Styles.form_field}`}>
                      <label>Title</label>
                      <input type="text" placeholder="Enter Title" value={editPlaylistData.title} name="title" onChange={(e) => setEditPlaylistData({ ...editPlaylistData, title: e.target.value })} />
                      {errors.title && <span>{errors.title}</span>}
                    </div>
                    <div className={`${Styles.form_field}`}>
                      <label>Description</label>
                      <textarea name="" id="" placeholder="Enter Description" value={editPlaylistData.description} onChange={(e) => { setEditPlaylistData({ ...editPlaylistData, description: e.target.value }); }}></textarea>
                    </div>
                    <div className={`${Styles.form_field}`}>
                      <label>Category</label>
                      <select name="category" value={editPlaylistData.category_id} onChange={(e) => setEditPlaylistData({ ...editPlaylistData, category_id: e.target.value })}>
                        <option value="">**** Select Category ****</option>
                        {
                          category.map((data, index) => {
                            return (
                              <option value={data.id}>{data.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className={`${Styles.form_field}`}>
                      <label>Language</label>
                      <select name="language" value={editPlaylistData.language_id} onChange={(e) => setEditPlaylistData({ ...editPlaylistData, language_id: e.target.value })}>
                        <option value="">**** Select Language ****</option>
                        {
                          language.map((data, index) => {
                            return (
                              <option value={data.id}>{data.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className={`${Styles.form_field}`}>
                      <label>Status</label>
                      <select name="status" value={editPlaylistData.status} onChange={(e) => setEditPlaylistData({ ...editPlaylistData, status: e.target.value })}>
                        <option value="">***** Select Status *****</option>
                        <option value="1">Publish</option>
                        <option value="0">Unpublish</option>
                      </select>
                    </div>
                    <div className={Styles.form_field}>
                      <label>Release Date</label>
                      <input type="date" name="release_date" value={editPlaylistData.release_date} onChange={(e) => setEditPlaylistData({ ...editPlaylistData, release_date: e.target.value })} />
                    </div>
                    <div className={`${Styles.form_field}`}>
                      <label>Profile Picture</label>
                      <div className={`${Styles.customFileInput}`}>
                        <input type="text" placeholder="Choose file..." value={editPlaylistData.profile_image_name} />
                        <input type="file" accept=".png,.jpg" id="file-input" onChange={handlePlaylistProfilePictureChange} name="ProfileImageStyle" />
                      </div>
                    </div>
                    <div className={`${Styles.form_field}`}>
                      <label>Cover Picture</label>
                      <div className={`${Styles.customFileInput}`}>
                        <input type="text" placeholder="Choose file..." value={editPlaylistData.cover_image_name} />
                        <input type="file" accept=".png,.jpg" id="file-input" onChange={handlePlaylistCoverPictureChange} name="coverImageStyle" />
                      </div>
                    </div>
                  </div>

                  <div className={`${Styles.buttons}`}>
                    <button className={`${Styles.action}`} onClick={handleEditPlaylistSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
                    <button className={`${Styles.cancel}`}
                      onClick={() => {
                        setEditPlaylistModule(false);
                        setAddTrackModal(false);
                        setLoading(false);
                        setEditPlaylistData({
                          title: "",
                          description: "",
                          status: "",
                          language_id: "",
                          category_id: "",
                          release_date: "",
                          channelId: channelId,
                          id: playlistId,
                          cover_image: "",
                          profile_image: ""
                        })
                      }}>
                      Cancel</button>
                  </div>
                </div>

              </div>
            </div>
          )
        }

        {
          addTrackModal === false &&
          <>
            <div className={`${Styles.search}`}>
              {
                !linkSong &&
                <>
                  <input type="text" placeholder="Search" value={linkSongSearch} onChange={(e) => setLinkSongSearch(e.target.value)} />
                  <button onClick={() => setLinkSong(true)}> <FaLink /> Link Song</button>
                </>
              }
              {
                linkSong &&
                <>
                  <input type="text" placeholder="Search" value={trackSearch} onChange={(e) => setTrackSearch(e.target.value)} />
                  <button onClick={() => setLinkSong(false)}> <FaLink /> Goto Song List</button>
                </>
              }
            </div>

            {
              !linkSong &&
              <div className={`${Styles.channel_data_table}`}>
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Track</th>
                      <th>Play</th>
                      <th>Duration</th>
                      <th>Created Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      playlistTracks.length === 0 &&
                      <tr><td colSpan="7"><div className={`${Styles.dataNotFound}`}>Track Not Found</div></td></tr>
                    }
                    {playlistTracks.map((data) => (
                      <tr key={data.id}>
                        <td>{data.id}</td>
                        <td className={`${Styles.name_image}`}>
                          <div className={`${Styles.track_image}`}>
                            <img src={data.music?.image_url} alt="Not supported" />
                          </div>
                          <p>{data.music?.title}</p>
                        </td>
                        <td className={`${Styles.play}`}>
                          <div className={`${Styles.play_div}`}>
                            <FaUsers />
                            <p>{data.music?.play_count === null ? "0" : data.play_count}</p>
                          </div>
                        </td>
                        <td className={`${Styles.time}`}>
                          <div className={`${Styles.time_div}`}>
                            <LuClock7 />
                            <p>{convertDuration(data.music?.track_duration)}</p>
                          </div>
                        </td>
                        <td>{new Date(data.music?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s+/g, '-')}</td>
                        <td className={`${Styles.delete_track}`}><MdDelete onClick={() => handleDeleteTrack(data.music?.id)} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }

            {/* {
              linkSong &&
              <div className={`${Styles.searchSong}`}>

                {
                  unlinkSongs.map((data, index) => {
                    return (
                      <div className={`${Styles.track_list}`}>
                        <p>{data.title}</p>
                        <button onClick={() => AddToList(data.id)}><IoAddCircleOutline />Add</button>
                      </div>
                    )
                  })
                }
              </div>
            } */}
            {
              linkSong &&
              <div className={`${Styles.searchSong}`}>
                {
                  unlinkSongs.filter(song => !playlistTracks.find(track => track.music?.id === song.id)).map((data, index) => {
                    return (
                      <div className={`${Styles.track_list}`}>
                        <p>{data.title}</p>
                        <button onClick={() => AddToList(data.id)}><IoAddCircleOutline />Add</button>
                      </div>
                    )
                  })
                }
              </div>
            }
          </>
        }

      </div>
    </div>
  )
}

export default PlaylistView
