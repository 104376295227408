import React from 'react';
import Styles from "../../assets/css/common/pagination.module.scss";

const Pagination = (props) => {

  let metadata = {
    pages : [],
    showing: props.data.showing,
    current: props.data.currentPage
  };

  const dataChange = () => {

    const pageData = [];

    let decrementPage = 0;
    for (let index = props.data.currentPage; index > 0; index--) {
      if(decrementPage < 3){
        pageData.push(index);
      }
      decrementPage++
    }

    let incrementPage = 0;
    for (let index = props.data.currentPage + 1; index <= props.data.totalPage; index++) {
      if(incrementPage <= 2){
        pageData.push(index);
      }
      incrementPage++
    }

    pageData.sort();

    const checkNext = metadata.current === props.data.totalPage ? false : true;
    const checkPrev = metadata.current > 1 ? true : false;

    metadata.pages= pageData;
    metadata.next= checkNext;
    metadata.prev= checkPrev;
    metadata.current= props.data.currentPage;
    metadata.totalPage= props.data.totalPage;

  }
  dataChange();

  return (
    <div className={`${Styles.paginationWrapper}`}>
          <div className={`${Styles.showing}`}>
            Showing: <select value={metadata.showing} onChange={(e) => props.pageClick(e.target.value, "show")}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
            {/* of 10 */}
          </div>
          <div className={`${Styles.pagination}`}>
            <button onClick={() => props.pageClick(Number(metadata.current) - 1, "page")} disabled={!metadata.prev}>PREV</button>
            {
              metadata.pages.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`${metadata.current === data ? Styles.active : ''}`}
                    onClick={() => props.pageClick(data, "page")}
                  >
                    {data}
                  </button>
                )
              })
            }
            <button onClick={() => props.pageClick(Number(metadata.current) + 1, "page")} disabled={!metadata.next}>NEXT</button>
          </div>
        </div>
  )
}

export default Pagination
