import React from "react";
import Styles from "../assets/css/advertisement.module.scss";

const Advertisement = () => {
  return (
    <div className={`${Styles.advertisement}`}>

      <div className={`${Styles.advertisement_heading}`}>
        <h2>Advertisement</h2>
      </div>
    </div>
  )
}

export default Advertisement
