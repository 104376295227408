import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/adminUser/user.module.scss";
import { IoMdClose } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
// import { LuPlusCircle } from "react-icons/lu"; 
import { BsSortDown } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BiCheck } from "react-icons/bi";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import Pagination from "../common/Pagination";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import { updatePage } from '../../features/pagination/paginationSlice';
import userprofile from "../../assets/images/Admin/admin_profile.png";
import ContentLoaderGif from "../../assets/images/contentLoader.gif";


const User = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [contentLoader, setContentLoader] = useState('true');

    // const [sortingValue, setSortingValue] = useState("asc");
  
    const [statusFilter, setStatusFilter] = useState('');
    const [filterDate, setFilterDate] = useState({
      from: '',
      to: ''
    });
    const [userFilter, setUserFilter] = useState({
      from: '',
      to: '',
      search: '',
      sorting: 'desc',
      page: "1",
      limit: "10"
    });

    const [pagination, setPagination] = useState({
      currentPage: 1,
      totalPage: 6,
      showing: 10
    });

    const dateStatusApply = () => {
      setUserFilter({...userFilter, from: filterDate.from, to: filterDate.to, status: statusFilter});
      setIsOpen(false);
    };
  
    const handleClear = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
    };

    const handleReset = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
      setUserFilter({...userFilter, from: "", to: "", status: ""});
      setIsOpen(false);
    };

    const convertDuration = (second) => {
      const minute = Math.floor(second / 60);
      const secondTime = (Math.ceil(second).toString().length == 1) ? `${Math.ceil(second)}0` : Math.ceil(second);
      console.log(minute);
      console.log(secondTime);
      return `${minute}:${secondTime}`;
    };

    const [userData, setUserData] = useState([]);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [deleteId, setDeleteId]= useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);

    const [dropDownUserManage, setDropDownUserManage] = useState({
      id: 0,
      open: false
    });
    const [deleteUserDrop, setDeleteUserDrop] = useState(false);
  
    const handleDropDown = (e) => {
      let value = parseInt(e.currentTarget.dataset.userId);
      if(dropDownUserManage.id === value){
        setDropDownUserManage({...dropDownUserManage, open: !(dropDownUserManage.open)})
      }

      if(dropDownUserManage.id !== value){
        setDropDownUserManage({id: value, open: true});
      }
    };

    const changePage = (count, type) => {

      if(type === "page"){
        setUserFilter({...userFilter, page: count});
      }

      if(type === "show"){
        setUserFilter({...userFilter, page: "1", limit: count});
        setPagination({...pagination, showing: count});
      }
    };

    useEffect(() => {

    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user_data"));
    let dataa = convertDuration(4.2356);
    console.log(dataa);

    const getUserData = async () => {
      try {
        const getUserList = await axios.get(`${baseUrl}admin/user/list`, {
        // const getUserList = await axios.get(`${baseUrl}admin/user/list/from=${filterDate.from}&to=${filterDate.to}&status=${statusFilter}&search=${userFilter.search}&sort=${userFilter.sorting}&page=${userFilter.page}&limit=${userFilter.limit}`, {
        // const getUserList = await axios.get(`${baseUrl}admin/user/list/${userData.id}?from=${filterDate.from}&to=${filterDate.to}&status=${statusFilter}&search=${userFilter.search}&sort=${userFilter.sorting}&page=${userFilter.page}&limit=${userFilter.limit}`, {

          params: {
            from: userFilter.from,
            to: userFilter.to,
            status: statusFilter,
            search: userFilter.search,
            sort: userFilter.sorting,
            page: userFilter.page,
            limit: userFilter.limit
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getUserList;
        console.log(result);
        if(result.data.status === 200){
          setContentLoader(result.data.data.user.length === 0 ? true : false);
          setUserData(result.data.data.user);
          setPagination({
            ...pagination,
            currentPage: result.data.data.pagination.currentPage,
            totalPage: result.data.data.pagination.totalPage,
          });
        }
      } catch (error) { 
        console.log("Error : ", error);
      }
    }
    getUserData();
    }, [userFilter, refresh]);

    const handleDeleteUser = async () => {
      if(deleteId != null){

        try{
          const token = localStorage.getItem("token");
          const data = await axios({
            method: 'POST',
            url: `${baseUrl}admin/user/delete/${deleteId}`,
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
  
        const result = data;
          if(result.data.status === 200){
            setLoading(false);
            setDeleteUserDrop(false);
            dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
            setDropDownUserManage({...dropDownUserManage, open: false});
            setRefresh(refresh+1);
          }else{
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
            setLoading(false);
            setDeleteUserDrop(false);
          }

        }catch (error){
  
          console.log(error);
          setDeleteUserDrop(false);
          if(error.response.data.status === 422){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
            setLoading(false);
            return;
          }
  
          console.log(error.response.data.errors.password);
  
          if("password" in error.response.data.errors){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.password[0]}));
            setLoading(false);
            return;
          }
        }
      }else{
      }
    }
  
    return (
      <div className={`${Styles.user}`}>

        {
          deleteUserDrop && (
          <div className={`${Styles.deleteUser}`}>
            <div className={`${Styles.innerDeleteUser}`}>
                <div className={`${Styles.heading}`}>
                    <p>Do you really want to delete this user?</p>
                </div>

                <div className={`${Styles.delete_image}`}>
                    <img src={DeleteIllustration} alt="not supported" />
                </div>

                <div className={`${Styles.buttons}`}>
                    <button onClick={handleDeleteUser}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete User"}</button>
                    <button onClick={() => {setDeleteUserDrop(false); setDropDownUserManage({id: 0, open: false});}}>Skip & Continue</button>
                </div>
            </div>
          </div>
          )
        }

        <div className={`${Styles.user_heading}`}>
          <h2>User</h2>
        </div>
  
        <div className={`${Styles.user_nav}`}>
  
          {/* Filter And Search Data */}
          <div className={`${Styles.filter_search_btn}`}>
            <div className={`${Styles.filter_sort}`}>
                <div className={`${Styles.filter}`}>
                  <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                  {isOpen && (
                  <div className={`${Styles.filter_dropdown}`}>
                    <div className={`${Styles.heading}`}>
                      <p>Filter</p>
                      <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                    </div>
  
                    <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div>
  
                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Status</p>
                        <p onClick={() => setStatusFilter('')}>Clear</p>
                      </div>
                      <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">**** Select Status ****</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
  
                    <div className={`${Styles.buttons}`}>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={dateStatusApply}>Apply Now</button>
                    </div>
                  </div>
                  )}
                </div>
  
                <div className={`${Styles.sort}`}>
                  <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                  {isSortOpen && (
                    <div className={`${Styles.sort_dropdown}`}>
                      <p onClick={() => {setUserFilter({...userFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(userFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setUserFilter({...userFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(userFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setUserFilter({...userFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(userFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setUserFilter({...userFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(userFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                    </div>
                  )}
                </div>
            </div>
            <div className={`${Styles.search}`}>
              <input type="text" placeholder="Search" onInput={(e) => setUserFilter({...userFilter, search: e.target.value})} />
              {/* <button onClick={() => navigate("/user/create")}> <LuPlusCircle /> Add User</button> */}
            </div>
          </div>
  
          {/* User Table */}
          <div className={`${Styles.user_List}`}>
            <div className={`${Styles.user_table}`}>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    {/* <th>Contact</th> */}
                    <th>Email</th>
                    <th>Date of Birthday</th>
                    <th>Status</th>
                    <th>Join Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    userData.length === 0 &&
                      <tr><td colSpan="7">
                        <div className={`${Styles.dataNotFound}`}>
                          {
                            contentLoader &&
                            <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                          }
                          {
                            !contentLoader &&
                            <p>Data not found</p>
                          }
                        </div>
                      </td></tr>
                  }
                  {userData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.id}</td>
                      <td className={`${Styles.image_name}`}>
                        <div className={`${Styles.image}`}>
                          {/* <img src={data.image_url} alt="User Picture1" /> */}
                          <img src={data.image_url == null ? userprofile : data.image_url} alt="User Picture"  />
                        </div>
                        <div className={`${Styles.user_name}`}>
                          <p>{data.first_name}</p>
                        </div>
                      </td>
                      {/* <td>{data.phone_number == null ? "N/A" : data.phone_number}</td> */}
                      <td>{data.email}</td>
                      {/* <td>{new Date(data.dob).toLocaleDateString('en-GB', {day: 'numeric', month: 'numeric', year: 'numeric'})}</td> */}
                      <td>{data.dob ? new Date(data.dob).toLocaleDateString('en-GB', {day: 'numeric', month: 'numeric', year: 'numeric'}) : 'N/A'}</td>
                      <td>{data.status === 1 ? "Active" : "Inactive"}</td>
                      <td>{new Date(data.createdAt).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/\s+/g, '-')}</td>
                      <td>
                        <div className={`${Styles.manage}`}>
                          <PiDotsThreeOutlineFill data-userId={data.id} onClick={handleDropDown} />
                          {
                            dropDownUserManage.id == data.id && dropDownUserManage.open === true && (
                              <div className={`${Styles.manageUser}`}>
                                <p onClick={() => navigate(`/user/edit/${data.id}`)} ><span><FiEdit /></span> <span>Edit</span></p>
                                <p onClick={() => {setDeleteUserDrop(!deleteUserDrop); setDeleteId(data.id)} }><span><RiDeleteBin6Line /></span> <span>Delete</span></p>
                              </div>
                            )
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Pagination */}
          <div>
            <Pagination data={pagination}  pageClick={changePage}/> 
          </div>
      </div>
    </div>
    )
  }

export default User
