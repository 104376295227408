import React from 'react';
import Layout from './layouts/Layout';

const App = () => {
  return (
    <>
      <Layout />
    </>
  )
}

export default App
