import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    popUpToggle: false,
    popUpDetails: {
        heading: "Success",
        message: "Add your success message here",
        type: "success"
    }
}

export const popUpSlice = createSlice({
    name: "popUpToggle",
    initialState: initialState,
    reducers: {
        popUpBoxTrigger: (state, action) => {
            state.popUpToggle = true;
            state.popUpDetails = {
                heading: action.payload.heading,
                message: action.payload.message,
                type: action.payload.type
            }
        },
        popUpBoxTriggerOff: (state) => {
            state.popUpToggle = false;
        }
    }
});

export const { popUpBoxTrigger, popUpBoxTriggerOff } = popUpSlice.actions;

export default popUpSlice.reducer;