import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toggle: false
}

export const toggleSlice = createSlice({
    name: "toggle",
    initialState: initialState,
    reducers: {
        sidebarOn: (state, action) => {
            state.toggle = false;
        },
        sidebarOff: (state, action) => {
            state.toggle = true;
        }
    }
});

export const { sidebarOn, sidebarOff } = toggleSlice.actions;

export default toggleSlice.reducer;
