import React, { useRef, useState, useEffect } from "react";
import Styles from "../../assets/css/components/admin/privacy&policy/privacy.module.scss";

const PrivacyPolicy = () => {
  
  const editorRef = useRef(null);
  const [initialContent, setInitialContent] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setInitialContent(editorRef.current.innerHTML);
  }, []);

  const handleSave = () => {
    const updatedContent = editorRef.current.innerHTML;
    let da = JSON.stringify(updatedContent);
    console.log(da);
    setInitialContent(updatedContent);
    setHasChanged(false);
    alert('Changes saved successfully!');
  };

  const execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
    checkForChanges();
  };

  const checkForChanges = () => {
    if (editorRef.current.innerHTML !== initialContent) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  return (
    <div className={`${Styles.pp}`}>
      <div className={`${Styles.pp_heading}`}>
        <h2>Privacy & Policy</h2>
      </div>

      <div className={`${Styles.pp_description}`}>
        <div className={`${Styles.privacy_container}`}>

          <div className={`${Styles.toolbar}`}>
            <button onClick={() => execCommand("bold")}><strong>B</strong></button>
            <button onClick={() => execCommand("italic")}><em>I</em></button>
            <button onClick={() => execCommand("underline")}><u>U</u></button>
            <button onClick={() => execCommand("strikeThrough")}><s>S</s></button>
            <button onClick={() => execCommand("formatBlock", "blockquote")}>“”</button>
            <button onClick={() => execCommand("formatBlock", "pre")}>Code</button>
            <button onClick={() => execCommand("insertUnorderedList")}>•</button>
            <button onClick={() => execCommand("insertOrderedList")}>1.</button>
            <button onClick={() => execCommand("formatBlock", "h1")}>H1</button>
            <button onClick={() => execCommand("formatBlock", "h2")}>H2</button>
            <button onClick={() => execCommand("formatBlock", "h3")}>H3</button>
            <select onChange={(e) => execCommand("fontName", e.target.value)} defaultValue="Sans Serif">
              <option value="Arial">Arial</option>
              <option value="Georgia">Georgia</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Sans Serif">Sans Serif</option>
            </select>
            <select onChange={(e) => execCommand("fontSize", e.target.value)} defaultValue="Normal">
              <option value="1">Small</option>
              <option value="3">Normal</option>
              <option value="5">Large</option>
            </select>
            <select onChange={(e) => execCommand("formatBlock", e.target.value)} defaultValue="Normal">
              <option value="p">Normal</option>
              <option value="blockquote">Quote</option>
              <option value="pre">Code</option>
            </select>
            <button onClick={() => execCommand("justifyLeft")}>A Left</button>
            <button onClick={() => execCommand("justifyCenter")}>A Center</button>
            <button onClick={() => execCommand("justifyRight")}>A Right</button>
            <button onClick={() => execCommand("removeFormat")}>Tx</button>
          </div>

          <div
            className={`${Styles.editor}`}
            contentEditable="true"
            ref={editorRef}
            onInput={checkForChanges}
            dangerouslySetInnerHTML={{
              __html: `<h2>Privacy Policy</h2>
              <p>The privacy policy is a legal, obligatory, and valid agreement between the Provider of the solution...</p>`
            }}
          ></div>

          <div className={`${Styles.save_btn}`}>
            <button onClick={handleSave} className={`${Styles.saveBtn}`}>Save</button>
            {/* {hasChanged && <span className={`${Styles.unsavedChanges}`}>Unsaved Changes</span>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
